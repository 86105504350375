function productLabel(product, variant) {
  return `${product.title}${
    variant.title !== 'Default Title' ? ` - ${variant.title}` : ``
  }`
}

export function track(eventName, payload = {}) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: eventName,
    ...payload,
  })
}

export function trackViewItem(product) {
  track('view_item', {
    ecommerce: {
      currency: 'JPY',
      value: product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.title,
          item_brand: product.brand,
          price: product.price,
          quantity: 1,
        },
      ],
    },
  })
}

export function trackCheckout(lineItems, subTotal) {
  track('begin_checkout', {
    ecommerce: {
      currency: 'JPY',
      value: subTotal,
      items: lineItems.map((item) => ({
        item_id: item.product.slug,
        item_name: item.productTitle,
        price: item.price,
        quantity: item.quantity,
      })),
    },
  })
}
